<template>
  <main :class="['currentPost']">
    <slide
      v-if="slides.length"
      :slides="slides"
    />
    <section class="postSection">
      <article v-if="post">
        <div
          v-if="isNaN(post.author)"
          :class="['author', themeColor] "
        >
          <!--          <img :src="post.author.avatar_urls['96']">-->
          <!--          <div class="desc">-->
          <!--            <span class="groupText">-->
          <!--              Por <span :class="themeColor">{{ post.author.name }}</span>, atualizado em {{ post.date | humanizeDate }}-->
          <!--            </span>-->
          <!--          </div>-->
          <div class="categoryNames">
            <!--            <span-->
            <!--              v-for="category in post.categories"-->
            <!--              :key="category.id"-->
            <!--            >-->
            <!--              {{ category.name }}-->
            <!--            </span>-->
          </div>
        </div>
        <div
          :class="[themeColor]"
          v-html="postContent"
        />
        <div style="clear: both" />
      </article>
    </section>
  </main>
</template>

<script>
/* eslint-disable global-require */

import { mapActions, mapState, mapMutations } from 'vuex';
import Slide from '@/components/slide.vue';

export default {
    name: 'Post',
    metaInfo() {
        return {
            title: this.post ? this.post.title.rendered : 'Cone Consultoria',
            meta: [
                { name: 'description', content: this.post ? this.post.excerpt.rendered : 'Consultoria e Planejamento' },
                { name: 'author', content: this.post ? this.post.author.name : 'Cone' },
            ],
        };
    },
    components: {
        Slide,
    },
    filters: {
        humanizeDate(value) {
            const date = new Date(value);
            return date.toLocaleDateString('pt-br', {
                year: 'numeric',
                month: ('long'),
                weekday: ('long'),
                day: 'numeric',
            });
        },
    },
    computed: {
        ...mapState('posts', ['currentPost', 'authors', 'posts', 'pages', 'medias']),
        ...mapState(['themeColor']),

        slug() {
            return this.$router.currentRoute.params.slug;
        },
        post() {
            return this.currentPost[0];
        },
        // eslint-disable-next-line vue/no-async-in-computed-properties
        slides() {
            if (this.post) {
                const p = document.createElement('p');
                let imageURL = this.post.jetpack_featured_media_url;
                p.innerHTML = this.post.excerpt.rendered;

                if (!imageURL) {
                    imageURL = this.post.featured_media ? this.medias[this.post.featured_media] : '';
                }

                return [{
                    title: this.post.title.rendered,
                    description: this.post.type === 'post' ? p.innerText : '',
                    imageURL,
                }];
            }

            return [];
        },

        postContent() {
            if (this.post) {
                return this.post.content.rendered;
            }
            return false;
        },
    },
    beforeRouteUpdate(to, from, next) {
        next((vm) => {
            vm.getData(vm, 'before');
        });
    },
    watch: {
        $route() {
            if (!this.postExist() && !this.pageExist()) {
                window.location.reload();
            } else {
                this.changeMenuVisibility(false);
            }
        },
    },
    created() {
        this.getData();
        document.documentElement.scrollTop = 0;
    },
    methods: {
        ...mapActions('posts', ['getPost', 'getAuthors', 'getMedia', 'getPages']),
        ...mapActions(['changeMenuVisibility', 'changeLoaderVisibility', 'changeThemeColor']),
        ...mapMutations('posts', ['setCurrentPost']),
        updateTitlePage(title, description) {
            document.title = title;
            document.querySelector('[name="description"]').setAttribute('content', description.replace(/<[^>]*>?/gm, ''));
        },
        getData(vue) {
            const vm = vue || this;
            const fields = {
                _fields: ['author', 'content', 'media', 'jetpack_featured_media_url', 'slug', 'id',
                    'title', 'status', 'type', 'date', 'excerpt', 'featured_media', 'categories', 'tags'],
                slug: vm.slug,
                status: 'publish',
            };

            const buildCurrentPost = (posts) => {
                vm.changeLoaderVisibility(false);
                vm.changeThemeColor(posts[0].categories[0].slug);
                this.getMedia(posts[0].featured_id);
                this.updateTitlePage(posts[0].title.rendered, posts[0].excerpt.rendered);
            };

            const buildCurrentPage = () => this.getPages(fields).then((pages) => {
                if (!pages.length) {
                    vm.changeLoaderVisibility(false);
                    vm.$router.push({
                        name: 'page-not-found',
                    });
                } else {
                    vm.changeLoaderVisibility(false);
                    this.updateTitlePage(pages[0].title.rendered, pages[0].excerpt.rendered);
                }
            });

            if (!this.postExist() && !this.pageExist()) {
                vm.changeLoaderVisibility(true);
                vm.getPost(fields).then((posts) => {
                    if (posts.length) buildCurrentPost(posts);
                    else buildCurrentPage();
                });
            } else if ('categories' in this.post) vm.changeThemeColor(this.post.categories[0].slug);
        },
        postExist() {
            const { slug } = this.$router.currentRoute.params;
            const currentPost = this.posts.find((post) => post.slug === slug);
            if (currentPost) {
                this.changeThemeColor(currentPost.categories[0].slug);
                this.changeLoaderVisibility(false);
                this.setCurrentPost([currentPost]);
                return true;
            }
            return false;
        },
        pageExist() {
            const { slug } = this.$router.currentRoute.params;
            const currentPost = this.pages.find((page) => page.slug === slug);

            if (currentPost) {
                this.changeThemeColor('default');
                this.changeLoaderVisibility(false);
                this.setCurrentPost([currentPost]);
                return true;
            }

            return false;
        },
    },
};
</script>
