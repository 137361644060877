<template>
  <div>
    <post
      v-if="slug"
    />
    <blog v-else />
  </div>
</template>

<script>
import Post from '@/components/Post.vue';
import Blog from '@/components/Blog.vue';
import { mapActions } from 'vuex';

export default {
    name: 'PostPage',
    components: {
        Post,
        Blog,
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.changeMenuVisibility(false);
            vm.changeIsBlog(false);
        });
    },
    computed: {
        slug() {
            return this.$router.currentRoute.params.slug;
        },
    },
    methods: {
        ...mapActions(['changeMenuVisibility', 'changeIsBlog']),
    },
};
</script>
